<template>
  <div>
    <filter-slot
      :filter="FiltersForSlot.filters"
      :total-rows="FiltersForSlot.totalRows"
      :paginate="FiltersForSlot.paginate"
      :start-page="FiltersForSlot.startPage"
      :to-page="FiltersForSlot.toPage"
      :filter-principal="FiltersForSlot.filterPrincipal"
      @reload="$refs['workplansTable'].refresh()"
      @reset-all-filters="resetAllFilters"
    >
      <template #buttons>
        <div>
          <b-button
            v-if="hasPermission && isPendingTab && isAnalystDepartment"
            @click="assignWorkplan()"
            class="ml-2"
            variant="outline-success"
            :disabled="isLoading"
          >
            <span v-if="isLoading">
              Assigning...<b-spinner small variant="success" />
            </span>
            <span v-else>Assign Automatic</span>
          </b-button>
        </div>
      </template>

      <b-table
        ref="workplansTable"
        slot="table"
        class="blue-scrollbar"
        :items="myProvider"
        :fields="visibleFields"
        :busy.sync="isBusy"
        primary-key="ncr_id"
        responsive="md"
        sticky-header="50vh"
        show-empty
        no-provider-filtering
        :per-page="FiltersForSlot.paginate.perPage"
        :current-page="FiltersForSlot.paginate.currentPage"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #cell(client)="{ item }">
          <span class="d-flex align-items-center" style="gap: 5px">
            <!-- <IconStatusAccount :status="item.status_account_id" fromBc /> -->
            <router-link
              target="_blank"
              :to="{
                name: clientDashboardRouteName,
                params: {
                  idClient: `${item.client_account_id}`,
                },
              }"
            >
              {{ item.client_name }}
            </router-link>
            <b-badge
              class="font10 ml-1"
              :variant="item.client_type_id == 2 ? 'info' : 'primary'"
              v-if="[6, 20, 21, 22].includes(item.module_id)"
            >
              {{ item.client_type_id == 2 ? "Digital" : "Regular" }}
            </b-badge>
          </span>
          <status-account
            :account="{ ...item, status: item.status_account_id }"
          ></status-account>
          <div class="d-flex align-items-center">
            <span class="d-block">
              {{ item.client_account }}
            </span>
          </div>
          <b-badge
            v-if="item.status == 'RETURNED'"
            variant="warning"
            class="ml-1"
            >{{ item.status }}</b-badge
          >
        </template>

        <template #cell(type_ncr)="{ item }">
          <b-badge
            v-if="item.type_ncr === 'AUTOMATIC'"
            class="ml-1 item-automatic"
            >{{ item.type_ncr }}</b-badge
          >

          <b-badge v-if="item.type_ncr === 'MANUAL'" class="ml-1 item-manual">{{
            item.type_ncr
          }}</b-badge>
        </template>
        <template #cell(agent)="{ item }">
          <span class="d-block">{{ item.agent }}</span>
          <span class="d-block">{{ item.created_at | myGlobalDay }} </span>
        </template>

        <template #cell(specialist)="{ item }">
          <div v-if="isAnalystDepartment">
            <feather-icon
              v-if="!item.specialist"
              @click="openModalAssign(item)"
              size="16"
              icon="UserPlusIcon"
              class="mr-50 text-success cursor-pointer"
            />
            <span v-if="item.specialist" class="d-block">{{
              item.specialist
            }}</span>
          </div>
          <div v-else>
            <span v-if="item.specialist">{{ item.specialist }}</span>
            <span v-else>-</span>
          </div>
        </template>

        <template #cell(risky)="{ item }">
          <div v-if="item.type_client == 1">
            <div v-if="item.typerisk == null">
              <feather-icon
                class="text-warning cursor pointer oscillate"
                v-b-tooltip.hover.left="
                  'Classify the risk level of this new client'
                "
                @click="
                  openRiskClassificationModal(
                    item.client_account_id,
                    null,
                    null,
                    item
                  )
                "
                icon="AlertTriangleIcon"
                size="20"
              />
            </div>

            <div v-else class="d-flex justify-content-center ml-2">
              <div
                @click="openRiskClassificationTrackingModal(item)"
                v-b-tooltip.hover.top="'View Tracking'"
                class="cursor-pointer"
              >
                <b-badge
                  style="width: 100px"
                  :variant="getRiskyBadgeVariant(item.level)"
                  >{{ getRiskyLevelName(item.level) }}</b-badge
                >
              </div>

              <div class="d-flex align-items-center">
                <feather-icon
                  icon="Edit2Icon"
                  class="cursor-pointer text-warning"
                  v-b-tooltip.hover.right="'Edit Classification'"
                  style="margin-left: 5px"
                  size="13"
                  @click="
                    openRiskClassificationModal(
                      item.client_account_id,
                      item.typerisk,
                      item.level,
                      item
                    )
                  "
                />
              </div>
            </div>
          </div>
          <div v-else>-</div>
        </template>


        <template #cell(rounds)="{ item }">
          <b-badge
            variant="primary"
            class="pointer"
            @click="openModalRounds(item)"
          >
            {{ item.total_rounds_ncr ? item.total_rounds_ncr : 0 }}
          </b-badge>
        </template>

        <template #cell(file)="{ item }">
          <div class="d-flex justify-content-center">
            <div style="width: 20px">
              <amg-icon
                v-if="item.route_pdf != null"
                v-b-tooltip.hover.top="'View PDF'"
                @click="viewPdf(item)"
                class="cursor-pointer mt-0 mr-0 bigger text-danger"
                icon="FilePdfIcon"
                style="height: 18px; width: 18px"
              />
            </div>
          </div>
        </template>

        <template #cell(client_type)="{ item }">
          <b-badge
            style="width: 90px"
            :class="
              item.type_client == 1
                ? 'badge-purple'
                : item.type_client == 2
                ? 'badge-navy'
                : 'badge-red'
            "
          >
            <span>{{ getBadgeText(item.type_client) }}</span>
          </b-badge>
        </template>

        <template #cell(recommended_services)="{ item }">
          <b-badge v-if="!displayedServices(item)" variant="light-danger">
            <div class="d-flex justify-content-center align-items-center gap-5">
              <feather-icon icon="SlashIcon" />
              <span class="lh-0"> None </span>
            </div>
          </b-badge>
          <b-badge v-else variant="light-info">
            <div class="d-flex justify-content-center align-items-center gap-5">
              <feather-icon icon="GridIcon" />
              <span class="lh-0">
                {{ displayedServices(item).length }} services
              </span>
            </div>
          </b-badge>
        </template>
        <template #cell(views)="{ item }">
          <feather-icon
            size="20"
            :class="
              item.view == 'PENDING'
                ? 'text-warning cursor-pointer oscillate'
                : 'text-success'
            "
            :icon="item.view == 'PENDING' ? 'CheckIcon' : 'CheckCircleIcon'"
            @click="item.view == 'PENDING' ? saveView(item) : false"
            v-b-tooltip.hover.right
            :title="item.view == 'PENDING' ? 'View' : 'Viewed'"
          />
        </template>

        <template #cell(updated_by)="{ item }">
          <span class="d-block">{{ item.updated_by }}</span>
          <span class="d-block">{{ item.created_at_trwp | myGlobalDay }} </span>
        </template>
        <template #cell(completed_by)="{ item }">
          <span class="d-block">{{ item.completed_by }}</span>
          <span class="d-block">{{ item.created_at_trwp | myGlobalDay }}</span>
        </template>

        <template #cell(steps)="{ item }">
          <div
            @click="beforeOpenModalSteps(item)"
          >
            <div
              v-if="item.available_complete_workplan != null"
              class="d-flex flex-column align-items-center"
            >
              <feather-icon
                icon="AlertTriangleIcon"
                class="text-warning"
                size="25"
              />
              <b-badge variant="dark">AVAILABLE WORKPLAN</b-badge>
            </div>
            <div
              v-else
              class="d-flex flex-column align-items-center"
            >
              <amg-icon
                size="25"
                :icon="iconsSteps[item.current_step - 1]"
                class="text-primary cursor-pointer"
              />
              <b-badge variant="dark">{{ steps[item.current_step - 1] }}</b-badge>
            </div>
          </div>
        </template>

        <template #cell(tracking)="{ item }">
          <div>
            <feather-icon
              class="text-info cursor-pointer"
              v-b-tooltip.hover.right="'Tracking'"
              @click="openWpModalTracking(item)"
              size="20"
              icon="ListIcon"
            />
          </div>
        </template>

        <template #cell(actions)="{ item }">
          <div class="d-flex justify-content-center align-items-center">
            <feather-icon
              class="text-primary cursor-pointer mr-1"
              v-b-tooltip.hover.right="'Completed Workplan'"
              @click="openActionModal(item)"
              size="20"
              icon="EyeIcon"
              v-if="isCompletedTab"
            />
            <feather-icon
              class="text-danger cursor-pointer mr-1"
              v-b-tooltip.hover.right="'Remove Workplan'"
              @click="deleteWorkplan(item)"
              size="20"
              icon="TrashIcon"
              v-if="isPendingTab || isToRevisionTab"
            />
          </div>
        </template>
      </b-table>
    </filter-slot>

    <risky-classification-modal
      v-if="showRiskClassificationModal"
      :type="typeRisk"
      :client_id="client_account_id"
      :level="levelRiskyClient"
      :comments="commentRiskyClient"
      :dataClient="dataModal"
      @refresh="refresh"
      @close="showRiskClassificationModal = false"
    />

    <risk-classification-tracking-modal
      v-if="showRiskClassificationTrackingModal"
      :data="infoclient"
      @close="showRiskClassificationTrackingModal = false"
    />

    <ModalRecommendService
      v-if="showModalRecommend"
      @close="showModalRecommend = false"
      @reload="refresh"
      :dataClient="dataClient"
      :gridSection="true"
    />

    <request-workplan-tracking
      v-if="showWpModalTracking"
      :dataWp="dataWp"
      @close="showWpModalTracking = false"
    />

    <add-round-modal
      v-if="showModalRounds"
      :dataRound="dataClient"
      :validateModal="0"
      :fromWpTable="true"
      :hideAddButton="true"
      @close="showModalRounds = false"
    />

    <ActionDoneReturnModal
      v-if="showModalAction"
      :dataClient="dataWp"
      :revision="revision"
      :section="'WORKPLAN'"
      @close="showModalAction = false"
      @reload="refresh"
    />
    <AssignSpecificModal
      v-if="showModalAssign"
      :item="selectedItem"
      :section="section"
      @close="closeModalAssign"
      @refresh="refresh"
    />
    <StepsCreditReport
      v-if="showModalSteps"
      @close="showModalSteps = false"
      :dataWorkPlan="dataWorkPlan"
      @reload="refresh"
    />
    <AlertCopyWorkPlan
      v-if="showAlertCopyWorkPlan"
      :dataWp="itemSelected"
      @closeModal="showAlertCopyWorkPlan = false"
      @response="responseAlert"
    />
  </div>
</template>
<script>
// DATA
import Fields from "@/views/commons/components/request-workplan/data/fields.js";
import Filters from "@/views/commons/components/request-workplan/data/filters.js";

// MODAL
import RiskyClassificationModal from "@/views/specialist-digital/views/clients/components/ModalInsertRisky.vue";
import RiskClassificationTrackingModal from "@/views/management/views/risky-clients/modals/ModalInsertCommentsRisky.vue";
import ModalRecommendService from "@/views/commons/components/ncr/components/report/modal/ModalRecommendService.vue";
import RequestWorkplanTracking from "@/views/commons/components/request-workplan/views/components/modal/RequestWorkplanTracking.vue";
import AddRoundModal from "@/views/specialist-digital/views/clients/components/AddRoundModal.vue";
import ActionDoneReturnModal from "@/views/commons/components/request-workplan/views/components/modal/ActionDoneReturnModal.vue";
import AssignSpecificModal from "@/views/commons/components/request-workplan/views/components/modal/AssignSpecificModal.vue";
import StepsCreditReport from "@/views/commons/components/request-workplan/views/components/modal/StepsCreditReport.vue";
import AlertCopyWorkPlan from "@/views/commons/components/request-workplan/views/components/Alert/AlertCopyWorkPlan.vue"

// COMPONENTS
import IconStatusAccount from "@/views/commons/components/zero-payment/views/IconStatusAccount.vue";

// SERVICES
import RequestWorkPlanService from "@/views/commons/components/request-workplan/services/request-workplan.service.js";
import ReportService from "@/views/commons/components/ncr/service/ncr.service";

// STORE
import { mapGetters, mapActions } from "vuex";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";

export default {
  components: {
    IconStatusAccount,
    RiskyClassificationModal,
    RiskClassificationTrackingModal,
    ModalRecommendService,
    RequestWorkplanTracking,
    AddRoundModal,
    ActionDoneReturnModal,
    AssignSpecificModal,
    StatusAccount,
    StepsCreditReport,
    AlertCopyWorkPlan,
  },
  data() {
    return {
      TimeExpired: false,
      Fields,
      items: [],
      FiltersForSlot: {
        filters: Filters,
        totalRows: 0,
        paginate: {
          perPage: 10,
          currentPage: 1,
        },
        startPage: 1,
        toPage: 1,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Search by name...",
          model: "",
        },
      },
      isBusy: false,
      showModalRecommend: false,
      showRiskClassificationModal: false,
      showRiskClassificationTrackingModal: false,
      showWpModalTracking: false,
      infoclient: "",
      typeRisk: "",
      client_account_id: "",
      levelRiskyClient: null,
      commentRiskyClient: null,
      dataWp: null,
      dataClient: null,
      dataModal: null,
      showModalRounds: false,
      showModalAction: false,
      showModalAssign: false,
      isLoading: false,
      revision: false,
      selectedItem: null,
      section: null,
      showModalSteps: false,
      dataWorkPlan: null,
      iconsSteps: [
        "UpdatedIcon",
        "SyncIcon",
        "DisputeIcon",
        "RecommendIcon",
        "CheckIcon",
      ],
      steps: [
        "UPDATE ITEMS",
        "SYNCHRONIZE",
        "DISPUTE",
        "RECOMMEND",
        "TO REVIEW",
      ],
      showCopyWpModal: false,
      itemSelected: null,
      showAlertCopyWorkPlan: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      getRefreshTable: "RequestWorkPlanStore/getRefreshTable",
      clientDashboardRoutes: "clients-store/clientDashboardRoutes",
    }),
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
    isAnalystDepartment() {
      return this.moduleId == 28;
    },
    metaIndex() {
      return this.moduleId == 5 ? 2 : 1;
    },
    isPendingTab() {
      const { routePending } = this.$route.matched[this.metaIndex].meta;
      return routePending == this.$route.name;
    },
    isCompletedTab() {
      const { routeCompleted } = this.$route.matched[this.metaIndex].meta;
      return routeCompleted == this.$route.name;
    },
    isToRevisionTab() {
      const { routeToRevision } = this.$route.matched[this.metaIndex].meta;
      return routeToRevision == this.$route.name;
    },
    isDeleteTab() {
      const { routeDeleted } = this.$route.matched[this.metaIndex].meta;
      return routeDeleted == this.$route.name;
    },
    visibleFields() {
      return this.Fields.filter((field) => field.visible);
    },
    clientDashboardRouteName() {
      return this.clientDashboardRoutes[this.moduleId] || null;
    },
    filterTypeClient() {
      return this.FiltersForSlot.filters[3].model;
    },
    getRiskType() {
      return this.FiltersForSlot.filters[4].model == 1 ? 1 : 2;
    },
    isCreditAnalyst() {
      return this.currentUser.role_id == 22;
    },
    hasPermission() {
      return this.isCeo || this.isChief || this.isSupervisor;
    },
    currentTab() {
      const idx = this.moduleId == 5 ? 3 : 2;
      const obj = {
        1: "PENDING",
        7: "COMPLETED",
        6: "WAITING FOR APPROVAL",
        8: "ANNUL",
      };
      return obj[this.$route.matched[idx].meta.status];
    },
    isAnalystLeader() {
      return this.currentUser.role_id == 21;
    },
  },
  async created() {
    await this.start();
  },
  methods: {
    ...mapActions({
      CHANGE_REFRESH_TABLE: "RequestWorkPlanStore/CHANGE_REFRESH_TABLE",
      A_REQUEST_WORKPLAN_COUNTERS:
        "RequestWorkPlanStore/A_REQUEST_WORKPLAN_COUNTERS",
    }),
    async start() {
      // FIELDS
      this.filterColumn("risky", this.isAnalystDepartment);
      // this.filterColumn("cr", this.isAnalystDepartment);
      this.filterColumn("steps", this.isAnalystDepartment);
      this.filterColumn("updated_by", this.isToRevisionTab);
      this.filterColumn("completed_by", this.isCompletedTab);
      this.filterColumn("steps", !this.isCompletedTab && this.isAnalystDepartment && !this.isDeleteTab);

      // this.filterColumn(
      //   "dispute",
      //   this.isAnalystDepartment && this.isPendingTab
      // );
      this.filterColumn("recommended_services", this.isToRevisionTab);
      this.filterColumn("rounds", this.isCompletedTab || this.isToRevisionTab);
      this.filterColumn(
        "specialist",
        !this.isCreditAnalyst && this.isPendingTab
      );
      this.filterColumn(
        "actions",
        !this.isDeleteTab &&
          this.isAnalystDepartment &&
          (this.isAnalystLeader ||
            this.isChief ||
            this.isCeo ||
            this.isSupervisor)
      );
      // FILTERS
      this.getFilterByKey("department").visible = this.isAnalystDepartment;
      this.filterColumn(
        "views",
        this.isCompletedTab && !this.isAnalystDepartment
      );

      if (this.isCompletedTab) {
        this.Fields.find((field) => field.key == "actions").label =
          "Show Details";
      } else {
        this.Fields.find((field) => field.key == "actions").label = "Actions";
      }
    },
    async deleteWorkplan(data) {
      try {
        const swal = await this.showConfirmSwal();
        if (swal.isConfirmed) {
          const params = { id: data.id, user_id: this.currentUser.user_id };
          await RequestWorkPlanService.deleteWorkplan(params);
          this.refresh();
          this.showSuccessSwal("Request Workplan deleted");
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    getFilterByKey(key) {
      const filter = this.FiltersForSlot.filters.find(
        (filter) => filter.key === `${key}`
      );
      return filter;
    },

    async myProvider(ctx) {
      try {
        const params = {
          from: this.getFilterByKey("from").model,
          to: this.getFilterByKey("to").model,
          per_page: ctx.perPage,
          page: ctx.currentPage,
          status: this.currentTab,
          text: this.FiltersForSlot.filterPrincipal.model,
          specialist: this.isCreditAnalyst
            ? this.currentUser.user_id
            : this.getFilterByKey("specialist").model,
          type_client: this.getFilterByKey("client_type").model,
          risk: this.getFilterByKey("risky_level").model
            ? this.getRiskType
            : null,
          level:
            this.getFilterByKey("risky_level").model > 1
              ? this.getFilterByKey("risky_level").model - 1
              : null,
          module: this.isAnalystDepartment
            ? this.getFilterByKey("department").model
            : this.moduleId,
        };

        const result = await RequestWorkPlanService.search(params);
        const data = result.data.map((item) => ({ ...item, showAll: false }));

        this.FiltersForSlot.startPage = result.from;
        this.FiltersForSlot.paginate.currentPage = result.current_page;
        this.FiltersForSlot.paginate.perPage = result.per_page;
        this.FiltersForSlot.totalRows = result.total;
        this.FiltersForSlot.toPage = result.to;

        return data || [];
      } catch (error) {
        return [];
      }
    },

    filterColumn(key, bool) {
      let field = this.Fields.find((field) => field.key == `${key}`);
      field.visible = bool;
    },

    refresh() {
      this.$refs.workplansTable.refresh();
      this.A_REQUEST_WORKPLAN_COUNTERS(this.moduleId);
    },


    async assignWorkplan() {
      this.isLoading = true;
      const params = {
        section: "REQUEST_WORK_PLAN",
      };
      const data = await RequestWorkPlanService.assignWorkplan(params);
      if (data.status == 200) {
        this.isLoading = false;
        this.$refs.workplansTable.refresh();
      }
    },

    openModalAssign(item) {
      this.selectedItem = item;
      this.section = "REQUEST_WORK_PLAN";
      this.showModalAssign = true;
    },

    closeModalAssign() {
      this.showModalAssign = false;
    },

    openRiskClassificationModal(data, type, level, dataClient) {
      this.levelRiskyClient = level;
      this.showRiskClassificationModal = true;
      this.client_account_id = data;
      this.typeRisk = type;
      this.commentRiskyClient = dataClient.comment_risky;
      this.dataModal = dataClient;
    },

    openRiskClassificationTrackingModal(data) {
      this.showRiskClassificationTrackingModal = true;

      let param = {
        id_account: data.client_account_id,
      };
      this.infoclient = param;
    },

    openWpModalTracking(item) {
      this.showWpModalTracking = true;
      this.dataWp = item;
    },

    openModalRounds(item) {
      this.dataClient = {
        id: item.ncr_id,
        account: item.client_account,
        idAccount: item.client_account_id,
        clientName: item.client_name,
        idWorkplan: item.id,
        clientData: {
          dob: item.dob,
          address: item.address,
          ssn: item.ssn,
          origin_country: item.origin_country,
        },
      };
      this.showModalRounds = true;
    },

    getBadgeText(type_client) {
      const types = {
        1: "NEW CLIENT",
        2: "REGULAR",
        3: "RETURNED",
      };
      return types[type_client] || "N/A";
    },

    getRiskyBadgeVariant(level) {
      const variants = {
        1: "info",
        2: "warning",
        3: "danger",
      };
      return variants[level] || "success";
    },

    getRiskyLevelName(level) {
      const names = {
        1: "RISK - LOW",
        2: "RISK - MEDIUM",
        3: "RISK - HIGH",
      };
      return names[level] || "NO RISK";
    },

    resetAllFilters() {
      this.FiltersForSlot.filters.forEach((filter) => {
        filter.model = null;
      });
      this.FiltersForSlot.filterPrincipal.model = null;
      this.$refs.workplansTable.refresh();
    },

    displayedServices(item) {
      if (item.recommended_services) {
        const services = JSON.parse(item.recommended_services);
        return services;
      }
    },
    shouldShowToggle(item) {
      if (item.recommended_services) {
        const services = JSON.parse(item.recommended_services);
        return services.length > 2;
      }
    },
    toggleShowAll(item) {
      item.showAll = !item.showAll;
    },
    async saveView(item) {
      try {
        const swal = await this.showConfirmSwal();
        if (swal.isConfirmed) {
          const params = {
            id: item.id,
            status_view: 2,
            save_by: this.currentUser.user_id,
          };
          const res = await RequestWorkPlanService.saveView(params);
          this.showSuccessSwal();
          item.view = "COMPLETED";
          this.A_REQUEST_WORKPLAN_COUNTERS(this.moduleId);
          return res;
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    async returnWorkPlan(id) {
      try {
        const swal = await this.showConfirmSwal(
          "Are you sure to return to pending?"
        );
        if (swal.isConfirmed) {
          const params = {
            id,
            created_by: this.currentUser.user_id,
          };
          const res = await RequestWorkPlanService.returnToPending(params);
          this.showSuccessSwal();
          this.refresh();
          return res;
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async completeWorkPlan(id) {
      try {
        const swal = await this.showConfirmSwal("Are you sure to complete?");
        if (swal.isConfirmed) {
          const params = {
            id,
            created_by: this.currentUser.user_id,
          };
          const res = await RequestWorkPlanService.completeWp(params);
          this.showSuccessSwal();
          this.refresh();
          return res;
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async openActionModal(item) {
      try {
        this.addPreloader();
        const { data } = await RequestWorkPlanService.getCompletedWpInfo({
          id: item.id,
        });

        this.showModalAction = true;
        this.dataWp = item;
        this.dataWp.rounds = data.rounds;
        this.dataWp.creditUtilized = data.creditUtilized;
        this.dataWp.deletedAccounts = data.deletedAccounts;
        this.dataWp.newAccounts = data.newAccounts;
        this.revision = this.isToRevisionTab;
      } catch (error) {
        console.error(error);
      } finally {
        this.removePreloader();
      }
    },
    async viewPdf(item) {
      try {
        this.addPreloader();
        let params = {
          file_pdf: item.route_pdf,
        };
        // const { data } = await NrcService.addUrlTemporaryPdf(params);
        const { data } = await RequestWorkPlanService.addUrlTemporaryPdf(
          params
        );
        window.open(data, "_blank");
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          error.message,
          "XIcon",
          "Something went wrong"
        );
      } finally {
        this.removePreloader();
      }
    },
    async responseAlert(item) {
      if(item){
        await this.copyWorkplan(item);
        this.showAlertCopyWorkPlan = false;
      } else {
        await this.copyWorkplan(item);
        this.openModalSteps(this.itemSelected);
        this.showAlertCopyWorkPlan = false;
      }
    },
    async beforeOpenModalSteps(item){
      if(item.available_complete_workplan != null){
        this.itemSelected = item;
        this.showAlertCopyWorkPlan = true;
      }else{
        this.openModalSteps(item);
      }
    },
    async openModalSteps(item) {
      try {
        const { data } = await RequestWorkPlanService.getCompletedWpInfo({
          id: item.id,
        });

        this.showModalSteps = true;
        this.dataWorkPlan = item;
        this.dataWorkPlan.rounds = data.rounds;
        this.dataWorkPlan.creditUtilized = data.creditUtilized;
        this.dataWorkPlan.deletedAccounts = data.deletedAccounts;
        this.dataWorkPlan.newAccounts = data.newAccounts;
        this.revision = this.isToRevisionTab;
      } catch (error) {
        console.error(error);
      } finally {
        this.removePreloader();
      }
    },
    async copyWorkplan(response){
      const params = {
        workplanId: this.itemSelected.id,
        response: response,
        userId: this.currentUser.user_id
      }
      const { data } = await RequestWorkPlanService.copyWorkplan(params);
      if(data.success){
        this.showToast(
          "success",
          "top-right",
          "Success!",
          "CheckIcon",
          "Successfully process"
        )
        if(response){
          this.$router.push({
            name: "sp-wp-completed"
          });
        }
        this.$refs['workplansTable'].refresh()
      }
    }
  },

  watch: {
    filterTypeClient(value) {
      this.FiltersForSlot.filters[4].disabled = value !== 1;

      if (value !== 1) this.FiltersForSlot.filters[4].model = null;
    },
    getRefreshTable(value) {
      if (value) {
        this.$refs["workplansTable"].refresh();
        this.CHANGE_REFRESH_TABLE(false);
      }
    },
  },
};
</script>
<style scoped>
.button {
  padding: 0;
  margin: 5px 2.5px;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
}

.badge-purple {
  background-color: #800080 !important;
  border-color: #800080 !important;
}

.badge-navy {
  background-color: #000080 !important;
  border-color: #000080 !important;
}

.badge-red {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
}

@keyframes oscillate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
span.item-automatic {
  background-color: #89375f !important;
  border-color: #89375f !important;
}
span.item-manual {
  background-color: #009fbd !important;
  border-color: #009fbd !important;
}
.oscillate {
  animation-name: oscillate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
</style>

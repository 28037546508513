export default [
  {
    key: "client",
    label: "Client",
    class: "text-left",
    visible: true,
  },
  {
    key: "type_ncr",
    label: "Type Ncr",
    class: "text-center",
    visible: true,
  },
  {
    key: "agent",
    label: "Agent/Advisor",
    class: "text-center",
    visible: true,
  },
  {
    key: "specialist",
    label: "Specialist",
    class: "text-center",
    visible: true,
  },
  {
    key: "risky",
    label: "Risk",
    class: "text-center",
    visible: true,
  },
  // {
  //   key: "cr",
  //   label: "CR",
  //   class: "text-center",
  //   visible: true,
  // },
  // {
  //   key: "dispute",
  //   label: "Dispute",
  //   class: "text-center",
  //   visible: true,
  // },
  {
    key: "rounds",
    label: "Rounds",
    class: "text-center",
    visible: true,
  },
  {
    key: "file",
    label: "File",
    class: "text-center",
    visible: true,
  },
  {
    key: "client_type",
    label: "Client type",
    class: "text-center",
    visible: true,
  },
  {
    key: "recommended_services",
    label: "recommendations",
    class: "text-center ",
    visible: true,
  },
  {
    key: "views",
    label: "views",
    class: "text-center",
    tdClass: "py-2",
    visible: true,
  },
  {
    key: "updated_by",
    label: "Updated by",
    class: "text-center",
    visible: true,
  },
  {
    key: "completed_by",
    label: "Completed by",
    class: "text-center",
    visible: true,
  },
  {
    key: "steps",
    label: "process",
    class: "text-center",
    visible: true,
  },

  {
    key: "tracking",
    label: "Tracking",
    class: "text-center",
    visible: true,
  },
  {
    key: "actions",
    label: "Actions",
    class: "text-center",
    visible: true,
  },
];

<template>
  <div>
    <b-modal
      v-model="isActive"
      hide-header-close
      hide-header
      :body-class="`rounded`"
      no-close-on-backdrop
      size="summary"
      @hidden="closeModal()"
      centered
      :ok-only="true"
      ok-variant="success"
      hide-footer
    >
      <div>
        <div
          class="d-flex justify-content-between align-items-center w-100 mt-1 mb-2"
        >
          <div class="d-flex align-items-center" style="gap: 7px">
            <span class="title-alert font-strong"> REUSE WORKPLAN</span>
          </div>
          <div
            class="close-modal"
            @click="closeModal()"
            :class="isDarkSkin ? 'bg-dark' : 'bg-light text-secondary'"
          >
            <feather-icon icon="XIcon" size="16" />
          </div>
        </div>
        <div class="d-flex align-items-center mt-1">
          <div class="icon-badge mr-1 text-success">
            <feather-icon icon="CheckCircleIcon" size="20"></feather-icon>
          </div>
          <span>
            <span class="text-uppercase">
              There is a completed workplan for this client from
              {{ dataWp.available_created_at | myGlobal }}, do you want to
              reuse it?
            </span>
          </span>
        </div>
        <hr />
        <div class="d-flex flex-column" style="gap: 15px">
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="UserIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              CLIENT
            </span>
            <span class="font-strong">{{ dataWp.client_name }}</span>
          </div>

          <!-- <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="UserCheckIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              WORKPLAN ORIGIN ACCOUNT
            </span>
            <span class="font-strong">{{ dataOldWp.account }}</span>
          </div> -->
        </div>
      </div>
      <hr />
      <div class="d-flex justify-content-center">
        <b-button variant="success" class="mx-1" @click="sendResponse(true)">
          Accept
        </b-button>
        <b-button variant="danger" class="mx-1" @click="sendResponse(false)">
          Reject
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import RequestWorkplanService from "@/views/commons/components/request-workplan/services/request-workplan.service.js";

export default {
  name: "AlertNcr",
  props: {
    dataWp: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      detailNcr: null,
      isActive: true,
      dataOldWp: {},
    };
  },
  async created() {
    console.log(this.dataWp);
    await this.getInfoOldWp();
  },
  methods: {
    async getInfoOldWp() {
      this.addPreloader();
      try {
        const params = {
          id: this.dataWp.id,
        };
        const { data } = await RequestWorkplanService.getInfoOldWp(params);
        this.dataOldWp = data[0];
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    sendResponse(response) {
      this.$emit("response", response);
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-badge {
  padding: 0.5rem 0.7rem;
  border-radius: 0.25rem;
}
.close-modal {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.5rem 0.7rem;
  background-color: #ececec;
  border-radius: 0.25rem;
  &:hover {
    background-color: #585858;
    color: #ececec;
    cursor: pointer;
  }
}

.title-alert {
  font-size: 1.2rem;
}
</style>

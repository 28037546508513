  <template>
  <b-modal
    v-model="ownControl"
    title-class="h3 text-white font-weight-bolder"
    size="md"
    title="Recommend service"
    @hidden="closeModal"
    centered
    no-close-on-backdrop
    no-close-on-esc
    ref="modalRecommend"
    footer-class="justify-content-center"
  >
    <template>
      <b-row class="custom-input">
        <b-col lg="6" class="mt-1">
          <b-input-group>
            <template #prepend>
              <b-input-group-text class="title_label"
                >MODULE
              </b-input-group-text>
            </template>
            <b-skeleton v-if="!finishedLoading" class="space" type="input" />
            <b-form-input
              v-if="finishedLoading && dataClient.program_name"
              v-model="dataClient.program_name"
              :disabled="true"
            />
            <b-form-input
              v-if="finishedLoading && dataClient.module_name"
              v-model="dataClient.module_name"
              :disabled="true"
            />
          </b-input-group>
        </b-col>
        <b-col lg="6" class="mt-1">
          <b-input-group>
            <template #prepend>
              <b-input-group-text class="title_label"
                >CLIENT
              </b-input-group-text>
            </template>
            <b-skeleton v-if="!finishedLoading" class="space" type="input" />
            <b-form-input
              v-if="finishedLoading"
              v-model="dataClient.client_name"
              :disabled="true"
            />
          </b-input-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12" class="mt-1">
          <ValidationObserver ref="form">
            <validation-provider
              v-slot="{ errors }"
              name="CONCEPT"
              rules="required"
            >
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_label"
                    >CONCEPT
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />
                <v-select
                  v-if="finishedLoading"
                  v-model="description"
                  :options="services"
                  :state="errors[0] ? false : null"
                  label="name"
                  multiple
                  class="flex-grow-1"
                />
              </b-input-group>

              <label v-if="errors[0]" class="text-danger"
                >Concept {{ errors[0] }}</label
              >
            </validation-provider>

            <validation-provider
              v-if="showCreditCard"
              v-slot="{ errors }"
              name="OTHERS"
              rules="required"
            >
              <b-input-group class="mt-1">
                <template #prepend>
                  <b-input-group-text class="title_label"
                    >TYPE CREDIT CARD
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />
                <v-select
                  v-if="finishedLoading"
                  v-model="creditCard"
                  :options="optionsChargesCreditCard"
                  :state="errors[0] ? false : null"
                  label="name"
                  class="flex-grow-1"
                />
              </b-input-group>
              <label v-if="errors[0]" class="text-danger"
                >Type Credit Card {{ errors[0] }}</label
              >
            </validation-provider>

            <validation-provider
              v-if="showAppointment"
              v-slot="{ errors }"
              name="OTHERS"
              rules="required"
            >
              <b-input-group class="mt-1">
                <template #prepend>
                  <b-input-group-text class="title_label"
                    >TYPE APPOINTMENTS
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />
                <v-select
                  v-if="finishedLoading"
                  v-model="appointment"
                  :options="optionsChargesAppointment"
                  :state="errors[0] ? false : null"
                  label="name"
                  class="flex-grow-1"
                />
              </b-input-group>
              <label v-if="errors[0]" class="text-danger"
                >Type of Appointments {{ errors[0] }}</label
              >
            </validation-provider>
            <!-- others services -->
            <validation-provider
              v-if="showOtherServices"
              v-slot="{ errors }"
              name="OTHERS"
              rules="required"
            >
              <b-input-group class="mt-1">
                <template #prepend>
                  <b-input-group-text class="title_label"
                    >TYPE OTHER SERVICES
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />
                <v-select
                  v-if="finishedLoading"
                  v-model="otherServices"
                  :options="optionsChargesOthers"
                  :state="errors[0] ? false : null"
                  label="name"
                  class="flex-grow-1"
                  multiple
                />
              </b-input-group>
              <label v-if="errors[0]" class="text-danger"
                >Type of Appointments {{ errors[0] }}</label
              >
            </validation-provider>
          </ValidationObserver>
          <b-form-group label="Comment" class="mt-1">
            <b-form-textarea
              rows="2"
              v-model="commentary"
              class="text-area-box"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
    <template #modal-footer>
      <b-button variant="success" @click="save">SAVE</b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import OtherPaymentOptions from "@/views/commons/components/clients/dashboard/options/pay-module/modals/OtherPaymentOptions.vue";
import ReportService from "@/views/commons/components/ncr/service/ncr.service";
import DisputeResultsService from "@/views/commons/components/dispute-results/services/dispute-results.service";
import SettingsServiceSp from "@/views/specialists/sub-modules/financial-approval/views/settings/service/settings.service.js";

export default {
  props: {
    dataClient: {
      required: true,
      type: Object,
    },
    roundLetterId: {
      required: false,
    },
    idBureau: {
      required: false,
    },
    idSection: {
      required: false,
    },
    comment: {
      required: false,
      type: String,
    },
    gridSection: {
      required: false,
      type: Boolean,
    },
    roundsToProcess: {
      required: false,
      default: null,
    },
  },
  components: {
    OtherPaymentOptions,
  },
  data() {
    return {
      notcharges: null,
      spinnerOn: false,
      specify: "",
      ownControl: false,
      finishedLoading: false,
      services: [],
      valDescription: false,
      description: [],
      amount_charge: null,
      validateCreditor: false,
      descriptionName: null,
      ammount: 0,
      isNcr: false,
      showCreditCard: false,
      showAppointment: false,
      showOtherServices: false,
      optionsChargesCreditCard: [],
      optionsChargesAppointment: [],
      optionsChargesOthers: [],
      creditCard: null,
      appointment: null,
      recommend: [],
      otherServices: [],
      chargesServicesData: [],
      commentary: null,
    };
  },

  async mounted() {
    this.ownControl = true;
    await this.search();
    this.finishedLoading = true;
    await this.getChargeServices();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    modul_id() {
      return this.currentUser.modul_id;
    },
    currentRoute() {
      return this.$route.name;
    },
    section() {
      if (this.currentRoute == "sp-wp-pending") {
        return "workplan";
      } else {
        return this.$route.params.section;
      }
    },
  },

  watch: {
    description() {
      this.showCreditCard = false;
      this.showAppointment = false;
      this.showOtherServices = false;
      this.description.forEach((element) => {
        switch (element.type_services) {
          case "CREDIT CARD":
            this.showCreditCard = true;
            this.optionsChargesCreditCard = this.getServiceChargeByTyPypes(
              element.type_services
            );
            break;
          case "APPOINTMENT":
            this.showAppointment = true;
            this.optionsChargesAppointment = this.getServiceChargeByTyPypes(
              element.type_services
            );
            break;
          default:
            this.showOtherServices = true;
            this.optionsChargesOthers = this.getServiceChargeByTyPypes(
              element.type_services
            );
            break;
        }
      });
    },
  },
  methods: {
    ...mapActions({
      A_REQUEST_WORKPLAN_COUNTERS:
        "RequestWorkPlanStore/A_REQUEST_WORKPLAN_COUNTERS",
      A_CLEAR_DISPUTE_DATA: "RequestWorkPlanStore/A_CLEAR_DISPUTE_DATA",
      A_UPDATE_REQUEST_COUNTERS:
        "BoostCreditDisputeResult/A_UPDATE_REQUEST_COUNTERS",
    }),

    refresh() {
      this.$refs.refCards.refresh();
    },

    async search() {
      try {
        const data = await SettingsServiceSp.searchServices();
        this.services = data;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    async getChargeServices() {
      try {
        const data = await ReportService.getChargeServices();
        this.chargesServicesData = data;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    getServiceChargeByTyPypes(type) {
      let dataServices = this.chargesServicesData.filter(
        (x) => x.type_services == type
      );
      return dataServices.length > 0 ? dataServices : [];
    },
    async save() {
      try {
        this.addPreloader();
        const validationPassed = await this.$refs.form.validate();

        if (validationPassed) {
          let nStatus;
          if (this.roundsToProcess !== null) {
            const { status } = await ReportService.processRound({
              rounds: this.setBoolToSendRound(this.roundsToProcess),
            });
            nStatus = status;
          } else {
            nStatus = 200;
          }

          if ((nStatus = 200)) {
            const params = this.buildParams();
            let res;

            if (this.section === "update") {
              res = await this.handleUpdateRequest(params);
            } else if (this.section === "workplan") {
              res = await this.handleWorkplanRequest(params);
            }
            this.handleSuccess(res);
          }
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.removePreloader();
      }
    },

    setBoolToSendRound(arr) {
      const filteredArr = arr.filter((obj) => obj.dispute_type != "Direct");

      if (filteredArr.length == 0) return arr; // only direct dispute, no actions

      const minValue = Math.min(...filteredArr.map((obj) => obj.round_number)); // some letters dispute

      const newArr = arr.map((obj) => ({
        ...obj,
        bool: obj.round_number == minValue && obj.dispute_type != "Direct", // set bool true first round if exists letters dispute
      }));

      return newArr;
    },

    buildParams() {
      if (this.creditCard) {
        this.recommend.push(this.creditCard);
      }
      if (this.appointment) {
        this.recommend.push(this.appointment);
      }
      let recommendedServicesArray = this.recommend.concat(this.otherServices);
      return {
        letters_id: this.roundLetterId,
        recommended_services: JSON.stringify(recommendedServicesArray),
        save_by: this.currentUser.user_id,
        id: this.idSection || this.dataClient.id,
        recommend_comment: this.commentary,
        comment: this.comment,
        client_account_id: this.dataClient.client_account_id,
        module_id: this.dataClient.module_id,
      };
    },

    async handleUpdateRequest(params) {
      const res = await DisputeResultsService.toRevisionUpdateRequest(params);
      return res && res.status === 200 ? res : null;
    },

    async handleWorkplanRequest(params) {
      const res = await ReportService.toRevision(params);
      return res && res.status === 200 ? res : null;
    },

    handleSuccess(res) {
      if (res) {
        this.showSuccessSwal(res.message);
        this.$emit("reload");
        if (this.currentRoute === "report-lead-specialists-ad-cr") {
          this.handleRouteRedirect();
        }
        this.A_CLEAR_DISPUTE_DATA([]);
        this.closeModal();
      }
    },

    handleRouteRedirect() {
      if (this.section === "update") {
        this.$router.push({ name: "sp-update-request-to-revision" });
      } else if (this.section === "workplan") {
        this.$router.push({ name: "sp-wp-to-revision" });
        this.A_UPDATE_REQUEST_COUNTERS(28);
        this.A_REQUEST_WORKPLAN_COUNTERS(28);
      }
    },

    handleError(error) {
      this.showErrorSwal(error);
    },

    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.space {
  width: 63%;
}
.title_label {
  width: 150px;
}
.custom-input .form-control {
  opacity: 1 !important;
}
</style>
